import React, { useEffect, useState } from 'react';
import {
  QuestionnaireBuilderApp,
  TabType,
  type Questionnaire,
} from '@clio/questionnaire-builder';
import { history } from '~/src/utils/history';
import {
  useGetDocumentFields,
  useUpdateClioQuestionnaire,
  useGetClioQuestionnaireById,
  useGetQuestionnaireTemplates,
  useGetClioQuestionnaireUrl,
  tryConvertToNumber,
  useUploadLogo,
  useRemoveLogo,
  useGetElement,
  useUpdateQuestionnaireSubmission,
} from '~/src/entities/questionnaires';
import Page, { LoadingOverlay } from '~/src/components/PageLayout';
import { useSnackbar } from 'notistack';
import {
  useCurrentOrg,
  useQuestionnairesPilotEnabled,
} from '~/src/entities/user';
import analyticsService from '~/src/services/analytics';
import { ShareQuestionnaireTab } from '~/src/entities/questionnaires/components/ShareQuestionnaireTab';
import env from '~/src/utils/env';

import '@clio/questionnaire-builder/dist/style.css';

export const QuestionnaireBuilderPage = ({
  questionnaireId,
  isAutoGenerated,
  hasAutoGenerateErrors,
}: {
  questionnaireId: string;
  isAutoGenerated?: boolean;
  hasAutoGenerateErrors?: boolean;
}) => {
  const snackbar = useSnackbar();
  const { org } = useCurrentOrg();
  const isQuestionnairesPilotEnabled = useQuestionnairesPilotEnabled();

  const orgFprint = org?.fprint!;
  const orgId = org?.id!;
  const areDevToolsEnabled = org?.enable_dev_tools!;

  const {
    data: questionnaireTemplates,
    isLoading: isTemplatesLoading,
    isFetching: isTemplatesFetching,
    isError: isTemplatesError,
  } = useGetQuestionnaireTemplates(orgFprint, questionnaireId);

  const {
    data: documents,
    isError: isDocumentMappingError,
    isLoading: isDocumentMappingLoading,
    mutate: getDocumentFields,
  } = useGetDocumentFields(orgFprint);

  const {
    data: questionnaire,
    refetch: refetchQuestionnaire,
    isError: isQuestionnaireError,
  } = useGetClioQuestionnaireById(orgFprint, questionnaireId);

  const {
    mutateAsync: updateSubmissionResponse,
    isLoading: isSubmissionLoading,
    isError: isSubmissionError,
  } = useUpdateQuestionnaireSubmission(orgFprint);

  // move churnzero button away from feedback button.
  const element = useGetElement('#cz_success_center_container');

  useEffect(() => {
    if (!element) return;

    element.setAttribute(
      'style',
      'top: 0px !important; right: 235px !important',
    );

    return () => {
      if (!element) return;

      // set the churnzero button back to its original position
      element.setAttribute(
        'style',
        'top: 4px !important; right: 74px !important',
      );
    };
  }, [element]);

  const updateClioQuestionnaire = useUpdateClioQuestionnaire(orgFprint);
  const uploadLogo = useUploadLogo(orgFprint);
  const removeLogo = useRemoveLogo(orgFprint);

  const handleSaveQuestionnaire = async (questionnaire: Questionnaire) => {
    await updateClioQuestionnaire.mutateAsync(
      {
        questionnaireId,
        clioQuestionnaire: { ...questionnaire },
      },
      {
        onError: async (response: any) => {
          switch (response.message) {
            case 'section reordering invalid':
              throw new Error('section reordering invalid');
            case 'question reordering invalid':
              throw new Error('question reordering invalid');
            default:
              snackbar.enqueueSnackbar(
                'Something went wrong, please try again later.',
                {
                  variant: 'error',
                },
              );
          }
        },
      },
    );

    const { data: updatedQuestionnaire } = await refetchQuestionnaire();

    if (!updatedQuestionnaire) return questionnaire;

    return updatedQuestionnaire;
  };

  const handleClose = () => {
    history.push(`/questionnaires/`);
  };

  useEffect(() => {
    if (!questionnaireTemplates) return;

    getDocumentFields({
      templateIds:
        questionnaireTemplates.map((template) =>
          String(template.template_id),
        ) ?? [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireTemplates]);

  const [matterId, setMatterId] = useState<null | string>(null);
  const [formValid, setFormValid] = useState(false);
  const [isLinkGenerated, setLinkGenerated] = useState<boolean>(false);
  const [rerender, setRerender] = useState<number>(0);
  const [currentTab, setCurrentTab] = useState<TabType>(TabType.Build);

  const {
    data: shareData,
    mutateAsync: shareLink,
    isError: isLinkError,
    isLoading: isLinkLoading,
  } = useGetClioQuestionnaireUrl();

  const [submissionTitle, setSubmissionTitle] = useState<string | null>(null);

  const handleGenerateLink = async () => {
    if (!matterId || !submissionTitle) return;

    const res = await shareLink({ orgFprint, questionnaireId, matterId });
    updateSubmissionResponse({
      submissionId: res.submission_id!,
      submissionUpdate: { title: submissionTitle },
    });
    analyticsService.track('Clicked on Generate Share Link', {
      questionnaireId,
      matterId,
      page: 'questionnaire_builder_page',
    });

    setSubmissionTitle(null);
    setLinkGenerated(true);
  };

  const handleGenerateAnotherLink = () => {
    setLinkGenerated(false);
    setFormValid(false);
    setMatterId(null);
    setRerender(rerender + 1);
  };

  const handleViewResponses = () => {
    if (!matterId || !shareData) return;

    const submissionId = shareData.submission_id;

    analyticsService.track('Clicked on Live Questionnaire', {
      questionnaire_id: questionnaireId,
      submission_id: submissionId,
      matter_id: tryConvertToNumber(matterId),
    });
    history.push(
      `/questionnaires/${questionnaireId}/responses/${submissionId}`,
    );
  };

  if (
    isDocumentMappingError ||
    isQuestionnaireError ||
    isTemplatesError ||
    isSubmissionError
  ) {
    snackbar.enqueueSnackbar(
      'Error, Failed to load Questionnaire Builder. Please try again later.',
      {
        variant: 'error',
      },
    );
    history.push(`/questionnaires/`);

    return <></>;
  }

  if (!isTemplatesFetching && questionnaireTemplates?.length === 0) {
    history.push(
      `/questionnaires/${questionnaireId}/select-documents/template-sets`,
    );
    return <></>;
  }

  if (
    questionnaire &&
    documents &&
    !isDocumentMappingLoading &&
    !isTemplatesLoading
  ) {
    const filteredSections = questionnaire.sections_attributes.filter((s) =>
      s._destroy != null ? !s._destroy : true,
    );

    const isShareable = filteredSections.some(
      (section) =>
        section.questions_attributes.filter((q) =>
          q._destroy != null ? !q._destroy : true,
        ).length !== 0,
    );

    const isValidSubmissionTitle =
      !!submissionTitle && submissionTitle.trim().length > 0;

    return (
      <Page disablePadding={true} showNavigation={false} showHeader={false}>
        <QuestionnaireBuilderApp
          onExit={handleClose}
          documents={documents}
          questionnaire={questionnaire}
          onSave={handleSaveQuestionnaire}
          onAnalyticsEvent={(event: any) => {
            analyticsService.track(event.name, event.properties);
          }}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          shareTabContents={
            <ShareQuestionnaireTab
              key={rerender} // simple way to clear the form
              questionnaireId={questionnaireId}
              questionnaireTitle={questionnaire.title}
              isDirty={questionnaire['dirty?'] ?? false}
              matterId={matterId}
              setMatterId={setMatterId}
              submissionId={shareData?.submission_id}
              setFormValid={setFormValid}
              questionnaireLink={shareData?.link}
              isLinkError={isLinkError}
              isLinkLoading={isLinkLoading || isSubmissionLoading}
              isLinkGenerated={isLinkGenerated}
              setLinkGenerated={setLinkGenerated}
              isShareable={isShareable}
              setCurrentTab={setCurrentTab}
              setSubmissionTitle={setSubmissionTitle}
            />
          }
          shareTabFooterButtonProps={{
            onGenerateLink: handleGenerateLink,
            onGenerateAnotherLink: handleGenerateAnotherLink,
            onViewResponses: handleViewResponses,
            isLinkGenerated,
            isLinkReadyToBeGenerated:
              matterId !== null && formValid && isValidSubmissionTitle,
          }}
          styleTabProps={{
            onLogoUpload: async (logo: File) => {
              await uploadLogo.mutateAsync({
                questionnaireId,
                logo,
              });
            },
            onLogoRemove: async () => {
              await removeLogo.mutateAsync({
                questionnaireId,
              });
            },
          }}
          baseUrl={env.clioQuestionnaireBaseUrl}
          hasAutoGenerateErrors={hasAutoGenerateErrors ? true : false}
          org={{
            id: orgId,
            areDevToolsEnabled,
            enablePilotUIFlags: !!isQuestionnairesPilotEnabled,
          }}
        />
      </Page>
    );
  }

  return isAutoGenerated ? (
    <LoadingOverlay
      title="Generating questions from your documents..."
      relative={false}
    />
  ) : (
    <LoadingOverlay title="Loading Questionnaire Builder..." relative={false} />
  );
};
